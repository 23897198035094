import { React, StyleSheet, View, Text, CenterWrapper, onUpdate, useState, Button } from 'lib'

import { Theme } from 'app'
import { WorldIcon, TapIcon, LockIcon, PhoneAlertIcon } from '../../assets/icons'

const ICON_SIZE = 50

const heading = 'The No.1 Smart Safety Tool To Protect Any Journey Or Activity'

const items = [
  {
    icon: <PhoneAlertIcon size={ICON_SIZE} color={Theme.colors.grad1}/>,
    title: 'Fully Automated',
    body: `You don’t need to touch or interact with your device to send alerts when needed.`,
  },
  {
    icon: <LockIcon size={ICON_SIZE} height={ICON_SIZE - 5} color={Theme.colors.grad1}/>,
    title: 'Totally Private',
    body: `No need to share your live location or notify people whenever you use Becon.`,
  },
  {
    icon: <TapIcon size={ICON_SIZE} color={Theme.colors.grad1}/>,
    title: 'Easy-to-Use',
    body: `Fast and simple, Becon can be activated to protect your journey in as little as 2 taps.`,
  },
  {
    icon: <WorldIcon size={ICON_SIZE - 5} color={Theme.colors.grad1}/>,
    title: 'Global Protection',
    body: ` The perfect travel companion, Becon works all over the world.`,
  },
]

const Triggers: React.FC = () => {
  const [sectionHeight, setSectionHeight] = useState(null)
  const { width } = Theme.hooks.size()

  onUpdate(() => {
    const finalHeight = document.getElementById('#how-it-works')?.clientHeight + Theme.spacing(6)
    if (finalHeight && finalHeight != sectionHeight) {
      setSectionHeight(finalHeight)
    }
  }, [width])

  return (
    <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.innerWrapper} id='#triggers'>
      <Text
        variant={['h4', 'textCenter']}
        text={heading}
      />
      <View style={styles.itemGrid}>
        {items.map(({ icon, body, title }, key) => (
          <View variant={['flex', 'column', 'alignCenter']} style={styles.buttons} key={key}>
            {icon}
            <View variant={['flex', 'column', 'alignCenter']}>
              <Text
                variant={['h5', 'textCenter', 'marginTop:2']}
                style={{ textTransform: 'capitalize' }}
                text={title}
              />
              <Text
                style={styles.text}
                variant={['p3', 'textCenter', 'marginTop:2']}
                text={body}
              />
            </View>
          </View>
        ))}
      </View>
      <Button variant={`gradient responsive white`} style={styles.learnMore} text={`Learn more about how Becon works`} to={'/guidance/triggers'}/>
    </CenterWrapper>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    backgroundColor: Theme.colors.white,
    position: 'relative',
  },
  innerWrapper: {
    marginTop: Theme.spacing(12),
    marginBottom: Theme.spacing(12),
    display: 'flex',
    flexWrap: 'wrap',
    gap: Theme.spacing(3),
    ...Theme.justifyCenter,
    ...Theme.alignCenter,
  },
  itemGrid: {
    display: 'grid',
    gap: Theme.spacing(2),

    [Theme.media.up('mid')]: {
      marginTop: Theme.spacing(5),
      marginBottom: Theme.spacing(5),
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(3),
      marginBottom: Theme.spacing(3),
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [Theme.media.down('small')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  buttons: {
    maxWidth: 320,
    justifyContent: 'space-between',
    padding: Theme.spacing(3),
    backgroundColor: Theme.colors.ice,
    borderRadius: Theme.spacing(2.25),
  },
  learnMore: {
    borderRadius: Theme.spacing(2.25),
  },
  text: {
    maxWidth: '80%',
  },
})

export default Triggers
